@use "../variables";

.Footer {
  background-color: variables.$main-white;
  box-shadow: variables.$shadowTop;
  height: var(--xs-height);
  padding: 0 !important;

  &__link {
    padding: 0 !important;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 10px;
    }
  }
}
