@use "sass:map";
@use "../mixins";
@use "../variables";

.AccountEdit {
  margin: 1rem 0;
  &__img {
    img {
      width: 80px;
      height: 80px;
    }
  }
  &__choose {
    padding: 0.5rem;
    input {
      background-color: map.get(variables.$gray, main);
      border-radius: 5px;
      color: #fff;
    }
  }
  &__submit {
    @include mixins.Flex($justify: flex-end);
    button {
      width: 100%;
      background-color: map.get(variables.$blue, main);
    }
  }
}