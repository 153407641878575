@use "sass:map";
@use "../mixins";
@use "../variables";

.ActivitiesViewDetails {
  &__details {
    &__title {
      @include mixins.fontStyle(18px, 400, map.get(variables.$gray, dark));
      padding: 1rem 0;
      border-bottom: 1px solid map.get(variables.$gray, main);
    }
    &__share {
      text-align: end;
      .dropdown {
        margin-right: 1rem;
        button {
          width: 28px;
          padding: 0;
          background-color: transparent !important;
          border-color: transparent !important;
          &:hover {
            background-color: transparent !important;
            border-color: transparent !important;
          }
          &.dropdown-toggle {
            &::after {
              content: none !important;
            }
          }
        }
        &-menu {
          &.show {
            padding: 0.5rem !important;
            left: -6rem !important;
            width: 230px;
            margin: 0;
          }
        }
      }
    }
    &__channel {
      padding: 1rem 0;
      @include mixins.fontStyle(14px, 400, map.get(variables.$gray, dark));
      img {
        object-fit: cover;
        object-position: top;
        width: 155px;
        height: 155px;
      }
      &__title {
        @include mixins.fontStyle(14px, 400, map.get(variables.$gray, darker));
        padding-bottom: 1rem;
      }
      &__edit {
        a {
          background-color: map.get(variables.$blue, main);
          width: 144px;
          @include mixins.Flex(row, center, center);
          @include mixins.fontStyle(14px, 400, #fff);
          border-radius: 0.25rem;
          text-transform: uppercase;
          text-decoration: none;
          margin-top: 0.5rem;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &__remove {
        button {
          background-color: map.get(variables.$red, main);
          width: 144px;
          @include mixins.Flex(row, center, center);
          @include mixins.fontStyle(14px, 400, #fff);
          text-transform: uppercase;
          margin-top: 0.5rem;
          &:hover {
            background-color: map.get(variables.$red, dark);
          }
        }
      }
    }
  }
  #player {
    width: 84vw;
    @media screen and (min-width: 720px) {
      width: 85vw;
    }
    @media screen and (min-width: 1200px) {
      width: 89vw;
    }
    &-Container {
      height: calc(100vh - 150px);
    }
  }
}
