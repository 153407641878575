
/// Style for the PageWrapper root element
.root-container {
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh; // fallback for unsupported browsers
  min-height: var(--vh100);
}

/// Style for the PageWrapper's <main> element
.main-container {
  box-sizing: border-box;
  display: block;
  overflow: auto;
  background-color: var(--mdc-theme-background, #ffffff);
  position:relative;
  flex-grow: 1;
  z-index: 0;
}

/// Styles a container as a vertical scrolling container with a fixed height.
.vertical-scroll-container {
  flex: auto;
  overflow: hidden;
  position: relative;
  display: flex;
}

/// Page Content Layout Containers /////
///// These containers are intended to be placed as a direct child of PageWrapper.
.content-container__normal {
  box-sizing: border-box;
  padding: 16px 48px;

  @media (max-width: 899px) {
    padding: 16px 24px;
  }

  @media (max-width: 599px) {
    padding: 8px;
  }
}

.content-container__no-padding {
  box-sizing: border-box;
  padding: 0;
}

/// General Layout Containers /////
///// Containers intended to be used as a child of a Page Content Layout Container
.grid-container {
  margin-top: 24px;
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
}
