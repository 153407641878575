@use "sass:map";
@use "../mixins";
@use "../variables";

.Account {
  &__header {
    min-height: 60px;
    box-shadow: variables.$shadowBottom;
    @include mixins.Flex(row, $align: center);
    &__title {
      @include mixins.fontStyle(20px, 400, map.get(variables.$gray, darker));
    }
  }
  &__no-user {
    min-height: 48px;
    align-items: center;
    margin: 0.5rem 0;
    a {
      @include mixins.fontStyle(16px, 400, map.get(variables.$gray, dark));
    }
    &__sign-in {
      box-sizing: content-box;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid map.get(variables.$gray, light);
      @extend .Account__no-user;
    }
  }
  &__banner {
    padding: 0 1rem;
    min-height: 104px;
    background-color: map.get(variables.$gray, dark);
    @include mixins.fontStyle(17px, 400, map.get(variables.$gray, light));
    @include mixins.Flex($align: center, $content: flex-end);
    img {
      width: 48px;
      height: 48px;
      border-radius: 45px;
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  hr {
    margin-bottom: 1rem;
    background-color: map.get(variables.$gray, dark);
  }
  &__userData {
    @media screen and (min-width: 480px) {
      padding: 0 1rem;
    }
    &__info {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        @include mixins.fontStyle(16px, 400, map.get(variables.$gray, dark));
        padding: 1rem;
      }
    }
    &__submit {
      @include mixins.Flex($justify: flex-end);
      border-bottom: 1px solid map.get(variables.$gray, light);
      padding-bottom: 1rem;
      button {
        width: 100%;
        background-color: map.get(variables.$blue, main);
      }
    }
    &__item {
      min-height: 48px;
      align-items: center;
      a,
      button {
        @include mixins.fontStyle(16px, 400, map.get(variables.$gray, darker));
        padding: 0;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
