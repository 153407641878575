.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.container {
  box-sizing: border-box;
  padding: 24px 16px;
  max-width: 736px;
}

.text {
  padding-bottom: 16px;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.button {
  padding: 0 32px;
  flex-grow: 0;
  justify-content: flex-end;

  @media (max-width: 599px) {
    flex-grow: 1;
    justify-content: center;
  }
}
