.channel-avatar {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.upload-icon {
  font-size: 96px;
}

.upload-graphic {
  background: #f4f4f4;
  border-radius: 50%;
  width: 136px;
  height: 136px;
  user-select: none;
}

.upload-content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
}

.custom-dialog {
  :global {
    .mdc-dialog__container {
      width: calc(100% - 48px);
    }
    .mdc-dialog__surface {
      max-width: calc(100% - 48px);
      width: calc(100% - 48px);
      height: calc(var(--vh100, 100vh) - 48px);
    }
  }
}

.base {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 36px;
  border: 2px dashed #eeeeee;
  border-radius: 4px;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.base__active {
  border-color: var(--mdc-theme-primary, #2196f3);
}
.base__accept {
  border-color: var(--mdc-theme-secondary, #00e676);
}
.base__reject {
  border-color: var(--mdc-theme-error, #ff1744);
}

.player-v2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 4px 16px;
  margin-right: 24px;
  label {
    margin-bottom: unset; // This is only required until we remove bootstrap import from
  }
}
