.container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  justify-content: space-around;
  min-height: 100vh; // Fallback for unsupported browsers
  min-height: var(--vh100);
}

.card {
  box-sizing: content-box;
  margin: auto;
  padding: 40px;
  width: 368px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);

  @media (max-width: 599px) {
    border: none;
    width: 312px;
    padding: 24px;
  }
}

.logo {
  display: flex;
  width: 213px;
  margin: auto;
}

.heading {
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
}

.input__full-width {
  width: 100%;
}

.forgot-password,
.forgot-password:hover {
  text-decoration: none;
  color: var(--mdc-theme-primary);
}

.action-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 8px;
}

.sso {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  padding-top: 24px;
}

.google-sign-in {
  align-self: center;
  margin-top: 24px;
}

.create-account {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.temp {
  padding-bottom: 8px;
}
