@use "../../styles/theme/active-theme" as theme;

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: black;
    text-align: center;
  }

  .your-channels_container {
    display: block;
    border-bottom: 1px solid rgba(theme.$on-surface, 0.12);
  }

  .your-channels_heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .your-channels_title {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
  }

  .your-channels_button {
    display: block;
  }


  .creator {
    background-color: rgb(248, 146, 112);
  }
  .browse {
    background-color: rgb(255, 173, 173);
  }
