@use "sass:map";
@use "../mixins";
@use "../variables";

.ChannelItem {
  padding: 1rem;
  @include mixins.fontStyle(14px, 400, map.get(variables.$gray, darker));

  &__img {
    @include mixins.Flex(row, center, center);
    border-radius: 50%;
    img {
      height: 120px;
      width: 120px;
      &.img-thumbnail {
        border: 0;
      }
    }
  }

  &__channel {
    text-align: center;
    a {
      @include mixins.fontStyle(14px, 400, map.get(variables.$gray, darker));
      text-decoration: none;
      &:hover{
        text-decoration: none;
        color: map.get(variables.$gray, dark);
      }
    }
  }

  &__edit {
    a {
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: map.get(variables.$blue, main);
      border-radius: 5px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
