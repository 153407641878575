.container {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 12px;
  // padding-top: 16px;
  align-items: center;
}
.title {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.action {
  flex: 0 0 auto;
  padding-left: 8px;
}