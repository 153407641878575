.group {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 36px;
}

.join-code {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  margin-right: 24px;
}

.user-limit {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
}
