/*======= Placeholders ==========*/

/*======= Mixins ==========*/
@mixin fontStyle($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin Flex($direction: row, $justify: inherit, $align: inherit, $content: inherit) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  align-content: $content;
}
