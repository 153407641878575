.container {
  width: 100%;
  margin: 16px 0px;
}
.details-container {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 24px;
  align-items: center;

  @media (max-width: 599px) {
    padding: 0 8px;
  }
}

.details {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
}
.avatar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  flex: 0 0 auto;
}

.name {
  max-height: 64px;
  margin-left: 24px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
