.container {
  display: flex;
  width: 50%;
  flex-flow: column nowrap;
  padding-right: 8px;
}
.section-headline {
  margin-bottom: 0;
  padding-bottom: 0;
}
.table-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  :global {
    div.mdc-data-table {
      flex-grow: 1;
    }
  }
}
.image {
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px 0;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-name {
  max-width: 192px;
  white-space: normal;
}
