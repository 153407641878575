.container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  justify-content: space-around;
  min-height: 100vh; // Fallback for unsupported browsers
  min-height: var(--vh100);
  padding: 40px 40px;
  @media (max-width: 599px){
    padding: unset;
  }
}
.card {
  margin: auto;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);

  @media (max-width: 599px) {
    border: none;
  }
}
.card-content {
  box-sizing: content-box;
  padding: 40px;
  width: 368px;
  @media (max-width: 599px) {
    width: 312px;
    padding: 24px;
  }
}
.logo--center {
  display: flex;
  width: 213px;
  margin: auto;
}
.logo--left {
  display: flex;
  width: 144.68px;
  height: 36px;
  margin-left: 0;
}
.heading--left {
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  margin-bottom: 24px;
}
.heading--center {
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  margin-bottom: 24px;
}
.action-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 8px;
}
.action-button__primary {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.action-button__secondary {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.sso {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  padding-top: 24px;
}
.google-sign-in {
  align-self: center;
  margin-top: 24px;
}