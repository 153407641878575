@use "@material/top-app-bar";

.mobile-container {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  background-color: var(--mdc-theme-surface, #ffffff);
  position: absolute;
  z-index: 24;
  top: 0;
  left: 0;
  height: 100vh; // Fallback for unsupported browsers
  height: var(--vh100);
  width: 100vw;
}

.my-top-app-bar {
  @include top-app-bar.fill-color(surface);
  @include top-app-bar.ink-color(on-surface);
}

.user {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 8px 16px;
}
.user-meta {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding: 0 8px;
  p {
    margin: 0;
  }
}

.account-menu-desktop_header{
  display: flex;
  flex-flow: row nowrap;
  padding: 0px 16px
}

.account-menu-desktop_header_avatar {
  display: block;
  padding-right: 8px;
}

.account-menu-desktop_header_user {
  display: flex;
  flex-flow: column nowrap;
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 4px;
  }
}