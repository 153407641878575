@use "sass:map";
@use "../mixins";
@use "../variables";

.SignIn {
  max-width: 100%;
  min-height: 720px;
  margin: 0 !important;
  @include mixins.Flex(row, center, center, center);
  position: relative;

  &__backward {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__content {
    min-width: 360px;
    @media screen and (min-width: 1200px) {
      border: 1px solid map.get(variables.$gray, light);
      border-radius: 8px;
      padding: 2rem 1rem;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      max-width: 213px;
    }

    p {
      @include mixins.fontStyle(24px, 600, map.get(variables.$gray, dark));
      margin: 1rem 0;
    }
  }

  &__form {
    max-width: 312px;
    margin: 0 auto;

    input {
      width: 90%;
      height: 46px;
      margin-bottom: 15px;
      margin-left: 5%;
      box-shadow: none;
      background: none;
      box-shadow: none !important;
      border: none;
      border-bottom: solid #AEB4BA 1px;
      font-size: 1rem;
    }

    &__forgot {
      @include mixins.fontStyle(14px, 500, map.get(variables.$blue, main));
      margin: 10px 0;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }
    }

    &__signin {
      button {
        background-color: map.get(variables.$blue, main);
        width: 88px;
        height: 36px;
      }
    }
  }

  &__google {
    max-width: 312px !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__create {
    max-width: 312px !important;
    height: 250px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 !important;

    @media screen and (min-width: 1200px) {
      height: 150px;
    }

    &__button {
      @include mixins.fontStyle(16px, 500, map.get(variables.$blue, main));
    }
  }
}
