.container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 100%;
  margin-top: 16px;
}

.visibility {
  max-width: 192px;
}

.capitalize {
  text-transform: capitalize;
}
