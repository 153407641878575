.container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  margin-top: 24px;
  min-height: 96px;
}
.action_container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 256px;
  justify-content: center;
  align-items: center;
  min-height: 56px;
}

.call-to-action {
  box-sizing: border-box;
  padding: 16px;

  @media (max-width: 599px){
    padding: 8px;
  }
}