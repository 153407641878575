@use "sass:map";
@use "../mixins";
@use "../variables";

.RegisterWrapper {
  margin-top: 5vh;
  @media screen and (min-width: 1200px) {
    margin-top: 0;
    border: 1px solid map.get(variables.$gray, light);
    border-radius: 8px;
    padding: 2rem 1rem;
  }
  padding: 0 1rem;
  @include mixins.Flex(row, $content: center);s

  &__content {
    max-width: 368px;
    &__img {
      margin-bottom: 0.5rem;
    }
    &__title {
      @include mixins.fontStyle(24px, 600, map.get(variables.$gray, dark));
      margin-bottom: 1rem;
    }
    &__login {
      @include mixins.Flex($align: center);
      a {
        padding-left: 1rem;
        @include mixins.fontStyle(16px, 500, map.get(variables.$blue, main));
      }
    }
    &__next {
      text-align: right;
      button {
        width: 88px;
        height: 36px;
        background-color: map.get(variables.$blue, main);
      }
    }
    &__continue {
      margin-left: auto;
      p {
        margin: 0;
        max-width: 176px;
        margin-left: auto;
        height: 36px;
        border-radius: 5px;
        background-color: map.get(variables.$blue, main);
        @include mixins.fontStyle(16px, 600, #fff);
        @include mixins.Flex(row, center, center);
      }
    }
  }
  a {
    text-decoration: none !important;
  }
}
