.wrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}
.container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.count {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  margin-right: 16px;
}

.error-message {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  max-width: 100%; // Allow message to grow full width if screen is smaller than 600px wide
  padding-top: 8px;

  // Prevent this text element from forcing its container to grow on screens larger than 600px wide
  @media (min-width: 600px) {
    max-width: 320px;
  }
}

.over-limit {
  color: var(--mdc-theme-error, #b03060); // semantic-ui red is the backup color
}

.under-limit {
  color: #016936; // semantic-ui green
}
