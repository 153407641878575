@use "sass:map";
@use "../mixins";
@use "../variables";

.ForgotPassword {
  input {
    width: 90%;
    height: 46px;
    margin-bottom: 15px;
    margin-left: 5%;
    box-shadow: none;
    background: none;
    box-shadow: none !important;
    border: none;
    border-bottom: solid #AEB4BA 1px;
    font-size: 1rem;
  }
  p {
    @include mixins.fontStyle(16px, 400, map.get(variables.$gray, dark));
  }
}