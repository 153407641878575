.drawer-app-content {
  flex: auto;
  overflow: hidden;
  position: relative;
  display: flex;
}

.top-app-bar-z7 {
  z-index: 7 !important;
}

//Nav Rail
.rail-container{
  position: relative;
  z-index: 2;
  left: 0;
  top: 0;
  height: var(--vh100);
  background-color: red;
  width: 72px;
  min-width: 72px;
}
