@use "./variables";
@use "./mixins";
@use "./utilities";

// mdc-theme
@use "../styles/theme";

//vendor...

//base...
@use "base/normalize.scss";

//components...
@use "./component/ActivityItem.scss";
@use "./component/ButtonIcon.scss";
@use "./component/ChannelItem.scss";
@use "./component/Footer.scss";
@use "./component/HeaderDesktopNav.scss";
@use "./component/PageWrapper.scss";
@use "./component/RegisterWrapper.scss";

//containers...
@use "./containers/Account.scss";
@use "./containers/AccountChannels.scss";
@use "./containers/AccountEdit.scss";
@use "./containers/Activities.scss";
@use "./containers/ActivitiesAddNew.scss";
@use "./containers/ActivitiesEdit.scss";
@use "./containers/ActivitiesViewDetails.scss";
@use "./containers/ConfirmEmail.scss";
@use "./containers/Channels.scss";
@use "./containers/ChannelDetails.scss";
// @use "./containers/Header.scss";
@use "./containers/Home.scss";
@use "./containers/SignIn.scss";
@use "./containers/NetworkWorking.scss";
@use "./containers/RecoveryConfirmation.scss";
@use "./containers/ForgotPassword.scss";
@use "./containers/SignUp.scss";
@use "./containers/RegisterConfirmation.scss";
@use "./containers/Terms.scss";

// 3rd party style fixes
@use "../styles/fixes";

body {
  display: flex;
  height: 100vh; // fallback for unsupported browsers
  height: var(--vh100);
}

.react-root {
  flex: auto;
  max-width: 100%;
}

body,
html {
  font-family: "Raleway", sans-serif;
  overscroll-behavior: none;

  .float-navigation {
    position: fixed;
    bottom: 0;
    left: 50%;
  }

  .DesktopWrapper {
    max-width: 448px !important;
    @media screen and (min-width: 1200px) {
      height: 100vh; // fallback for unsupported browsers
      height: var(--vh100);
      @include mixins.Flex($align: center);
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
  // .dropdown-item.active,
  // .dropdown-item:active {
  //   background-color: transparent;
  // }
}
