@use "sass:map";
@use "../mixins";
@use "../variables";

.ActivityItem {
  padding: 1rem 0;
  &__img {
    img {
      width: 100%;
      height: 205px;
      object-fit: cover;
      object-position: top;
    }
  }
  &__desc {
    margin-top: 1rem; 
    &__profile {
      img {
        min-width: 34px;
        min-height: 34px;
      }
    }
    &__title {
      @include mixins.fontStyle(13px, 400, map.get(variables.$gray, dark));
      min-height: 34px;
    }
    &__channel {
      a {
        span {
          @include mixins.fontStyle(18px, 400, map.get(variables.$gray, darker));
        }
      }
    }
  }
}
