/* ----- Flex ------ */

.flex {
  display: flex !important;
}
.d-row {
  flex-direction: row !important;
}
.d-column {
  flex-direction: column !important;
}
.x-center {
  display: flex !important;
  justify-content: center !important;
}
.x-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.x-around {
  display: flex !important;
  justify-content: space-around !important;
}
.x-between {
  display: flex !important;
  justify-content: space-between !important;
}
.y-center {
  display: flex !important;
  align-items: center !important;
}
.xy-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.xy-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

/* ----- Layout ------ */

.m0-auto {
  margin: 0 auto;
}

