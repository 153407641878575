.content {
  padding: 0 48px;

  @media (max-width: 899px) {
    padding: 0 24px;
  }

  @media (max-width: 599px) {
    padding: 0 12px;
  }
}

:global(.mdc-tab-bar .mdc-tab){
  min-width: 90px;
  max-width: 360px;
}
