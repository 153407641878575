.container {
  margin-top: 16px;
}

.heading {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.limitInput {
  margin-top: 8px;
}
