@use "sass:map";
@use "../mixins";
@use "../variables";

.PageWrapper {
  &__children {
    margin: var(--xs-height) auto;
  }
  &__title {
    padding: 1rem 4rem;
    @include mixins.fontStyle(24px, 400, map.get(variables.$gray, darker));
    
  }
}