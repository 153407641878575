@use "sass:map";
@use "../mixins";
@use "../variables";

.ActivitiesAddNew {
  padding: 1rem 0;
  &__title {
    p {
      @include mixins.fontStyle(18px, 400, map.get(variables.$gray, darker));
    }
  }
  &__choose {
    input {
      background-color: map.get(variables.$gray, main);
      border-radius: 5px;
      color: #fff;
    }
  }
  &__submit {
    button {
      width: 100%;
      background-color: map.get(variables.$blue, main);
    }
  }
}
