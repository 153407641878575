.container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.reset-button {
  margin-left: 8px;
  margin-bottom: 8px;
}