$scrim-opacity: 0.32;
$scrim-color: rgba(0, 0, 0, $scrim-opacity);

.container {
  box-sizing: border-box;
  width: 336px;
  margin-bottom: 24px;
  margin-right: 8px;
}
.card__selected {
  border: solid 1px var(--mdc-theme-primary, blue);
}

.meta_container {
  display: flex;
  flex-flow: row nowrap;
  margin: 8px 24px 8px 16px;
}
.meta-text_container {
  display: flex;
  flex-flow: column nowrap;
}

.title {
  // margin: 8px 24px 8px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* limits the text to 2 lines */
  -webkit-box-orient: vertical;
  min-height: 44px;
}
