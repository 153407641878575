@use "../../styles/theme/active-theme" as theme;

.title__container {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid rgba(theme.$on-surface, 0.12);
  min-height: 56px;
  padding-top: 20px;
}
.title__title {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
}

.title__share {
  display: flex;
  align-self: flex-start;
}

.channel {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
}

.channel__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-height: 80px;
  padding: 16px 0;
  box-sizing: border-box;
}

.channel__avatar {
  width: 48px;
  height: 48px;
}

.channel__name {
  padding-left: 16px;
}

.activity__edit {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
}

.description__container {
  max-width: 600px;
  padding-left: calc(48px + 16px);
}