@use "sass:map";
@use "../mixins";
@use "../variables";

.AccountChannels {
  width: 100%;
  // padding: 1rem;
  &__title {
    p {
      text-align: center;
      @include mixins.fontStyle(30px, 400, map.get(variables.$gray, darker));
    }
  }
  &__channels {
    &__empty {
      p {
        text-align: center;
        @include mixins.fontStyle(16px, 400, map.get(variables.$gray, dark));
      }
    }
  }
}
