.container {
  width: 100%;
  margin: 16px 0 4px;
}
.details-container {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 24px;
  align-items: center;

  @media (max-width: 599px) {
    padding: 0 8px;
  }
}
.banner {
  width: 100%;
  min-height: 16.5vw;
  height: calc(100vw / 6.2 - 1px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: flex-end;
}

.channel-actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding: 16px 24px;
}

.details {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
}
.avatar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  flex: 0 0 auto;
}

.name {
  max-height: 64px;
  margin-left: 24px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details_join-leave {
  margin-left: 8px;
}

.details_action {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  margin-left: 24px;
  align-items: center;
}
