/* ----- Colors ------ */
$main-yellow: #ece400;

$main-green: #09eb18;

$red: (
  main: #f02720,
  dark: #bb140e,
);

$blue: (
  main: rgba(0, 168, 225, 1),
);

$gray: (
  light: rgba(232, 232, 232, 1),
  main: rgba(0, 0, 0, 0.16),
  dark: rgba(17, 17, 17, 0.6),
  darker: #111111,
);

$main-white: rgba(255, 255, 255, 1);

$shadowBottom: 0px 4px 2px rgba(0, 0, 0, 0.16);
$shadowTop: 0px -2px 2px rgba(0, 0, 0, 0.16);

/* ----- Layout ------ */
:root {
  --xs-height: 50px;
}
