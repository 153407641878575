.collection-card {
  width: 344px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.selected-card {
  border: 1px solid var(--mdc-theme-primary);
}
.content-primary {
  padding: 16px; // Alternatively could use 1rem
}