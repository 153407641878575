.container {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 24px 16px;
}

.items_container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  // padding: 24px 16px;

  @media (max-width: 599px) {
    flex-flow: row wrap;
  }
}

.graphic {
  padding-bottom: 8px;
}

.item {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;

  padding: 24px 16px 48px 16px;
  max-width: 300px;

  @media (max-width: 599px) {
    min-width: 192px;
    max-width: 300px;
  }
}

.title {
  overflow: hidden;
  display: block;
  text-align: center;
}
