.container {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 16px;

}

.card {
  padding: 16px;
}

.icon {
  vertical-align: middle;
  margin: inherit;
}