@use "sass:map";
@use "../mixins";
@use "../variables";

.HeaderDesktopNav {
  &__collapse {
    flex-grow: inherit !important;
    .dropdown-menu.show {
      width: 288px;
      .dropdown-item {
        img {
          width: 40px;
          height: 40px;
          margin-top: 0.5rem;
        }
        &__name {
          @include mixins.fontStyle(16px, 700, map.get(variables.$gray, darker));
        }
        &__email {
          @include mixins.fontStyle(14px, 400, map.get(variables.$gray, darker));
        }
        a {
          @include mixins.fontStyle(14px, 400, map.get(variables.$blue, main));
        }
      }
    }
  }
  &__dropdown {
    a {
      &::after {
        content: none;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .dropdown-menu {
      right: 0;
      left: inherit;
    }
    &__item {
      min-height: 48px;
      align-items: center;
      a {
        @include mixins.fontStyle(16px, 400, map.get(variables.$gray, darker));
        padding: 0;
        &:hover {
          text-decoration: none;
        }
      }
      span {
        @include mixins.fontStyle(16px, 400, map.get(variables.$gray, darker));
        padding: 0;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
