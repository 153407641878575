.wrapper {
  max-width: 100%;
}
.table_container {
  max-width: 100%;
}

.channel_container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  place-items: start auto;
  max-width: 100%;
}
.channel-image {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 6px 0 6px 20px;

  @media (max-width: 499px) {
    margin: 6px 0 6px 0;
  }
}
.channel-name_container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1 1 auto;
  padding-left: 16px;
  margin: 5px 0;

  @media (min-width: 600px) {
    max-width: 60vw;
  }
  @media (max-width: 599px) {
    max-width: 58vw;
  }
  @media (max-width: 499px) {
    max-width: 52vw;
  }
  @media (max-width: 399px) {
    max-width: 45vw;
  }
  @media (max-width: 328px) {
    max-width: 38vw;
  }
}
.channel-name {
  overflow: hidden;
  white-space: normal;
}
.channel-id {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
