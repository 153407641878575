.container {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  margin-top: 8px;
  flex-grow: 1;
}

.chips_container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto hidden;
  max-height: 52px; // 48px default height + 4px of extra bottom padding = height
}
.sort_container {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
}

.chipset {
  display: block;
  padding-bottom: 8px; // Overridding the default bottom padding value of 4px.
}
