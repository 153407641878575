.ButtonIcon {
  border: 0;
  background-color: transparent !important;

  &--default {
    @extend .ButtonIcon;
  }
  &--bgblack-white {
    @extend .ButtonIcon;
    background-color: black;
    color: #ffffff;
  }
  &--blue {
    @extend .ButtonIcon;
    // color: $main-blue;
  }
}
