.share__container {
  display: block;
  width: 100%;
  padding-bottom: 24px;
}

.buttons__container {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 16px
}

.share-this__container {
  margin-bottom: 16px;
}

.share__title {
  margin-bottom: 8px;
  text-transform: capitalize;
}

.item {
  margin-right: 16px; 
}

.link__container {
  display: flex;
  align-items: center;
}

.link-input {
  flex-grow: 1;
  margin-right: 4px;
  min-width: 400px;

  @media (max-width: 599px){
    min-width: 200px;
  }
}