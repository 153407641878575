.embed-box {
  min-width: 360px;

  @media (max-width: 599) {
    min-width: 280;
  }
}

.checkbox-container {
  label {
    margin-bottom: unset;
  }
}