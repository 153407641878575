.loading_container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  height: 100vh; // Fallback for unsupported browsers
  height: var(--vh100);
}

.accessDenied {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;

  & h3 {
    font-size: 20px;
    margin: 0;
  }

  & p {
    margin-top: 5px;
  }
}
