@use '../../../styles/config';

.container {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  min-height: config.$activityMetadata_collapsed_height;
  width: 100%;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 4;
  position: relative;
}
.title {
  display: inline-flex;
  flex: 1 0 auto;
  align-items: center;
  margin-right: 8px;
}