$scrim-opacity: 0.32;
$scrim-color: rgba(0, 0, 0, $scrim-opacity);

.container {
  box-sizing: border-box;
  width: 336px;
  margin-bottom: 24px;
  margin-right: 8px;
}
.card__selected {
  border: solid 1px var(--mdc-theme-primary, blue);
}
.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overflow-icon {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); //shadow to make icon visible on any color
}
.meta_container {
  display: flex;
  flex-flow: row nowrap;
  margin: 8px 24px 8px 16px;
}
.meta-text_container {
  display: flex;
  flex-flow: column nowrap;
}
.author-avatar_container {
  margin-right: 16px;
}

.title {
  // margin: 8px 24px 8px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locked_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  background-color: $scrim-color;
}

.locked-icon {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.overflow-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  justify-content: flex-end;
}

.banner {
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 72px 72px 0 0;
    border-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0)
      rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }

  &__text {
    user-select: none;
    white-space: nowrap;
    position: absolute;
    top: 16px;
    padding: 0 4px;
    font-weight: 600 !important;
    transform: rotate(-45deg);
  }
}
