.container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  // background-color: #b3c6fd;
  background-color: #d8d8d8;
  padding: 24px 8px;

  @media (max-width: 599px) {
    flex-flow: row wrap;
  }
}

.point {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  padding: 24px 16px 36px 16px;
  max-width: 300px;
  background-color: #ffffff;

  @media (max-width: 599px) {
    min-width: 192px;
    max-width: 80vw;
  }
}

.graphic {
  padding-bottom: 16px;
}
