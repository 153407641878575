.content {
  padding: 0 48px;

  @media (max-width: 899px) {
    padding: 0 24px;
  }

  @media (max-width: 599px) {
    padding: 0 12px;
  }
}

.page-title {
  margin-top: 24px;
}

.key-points_background {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 1%,
      rgba(0, 0, 0, 0.6) 15%
    ),
    url(../../assets/images/landing/key-points/black-chairs-background@05x.jpg);
}

.key-point-item_graphic {
  width: 128px;
  height: 128px;
  background-color: var(--mdc-theme-primary, rgba(127, 127, 127, 1));
  border-radius: 8px;
  padding: 8px;
}

.feature-item_graphic {
  width: 64px;
  height: 64px;
  background-color: var(--mdc-theme-primary, rgba(127, 127, 127, 1));
  border-radius: 50%;
  padding: 8px;
}

.featured-image_container {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 168, 225, 1) 51%,
    rgba(36, 61, 149, 1) 100%
  );
}
