.input__full-width {
  width: 100%;
}

.name {
  display: flex;
  flex-flow: row nowrap;
}

.first-name {
  padding-right: 4px;
  flex-grow: 0;
  max-width: 50%;
}
.last-name {
  padding-left: 4px;
  flex-grow: 0;
  max-width: 50%;
}