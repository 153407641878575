@use "../../styles/theme/active-theme" as theme;
.container {
  display: block;
  border-bottom: 1px solid rgba(theme.$on-surface, 0.12);
}

.content {
  display: flex;
  flex-flow: row nowrap;
  max-width: 800px;
  margin: auto;
  align-items: center;

  @media (max-width: 599px) {
    flex-flow: row wrap;
    margin-top: 16px;
  }
}

.message {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (max-width: 599px) {
    margin-left: 16px;
  }
}

.media {
  display: flex;
  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: 56px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;

  @media (max-width: 599px) {
    margin-left: 36px;
  }

  @media (min-width: 1200px) {
    margin-left: 90px;
  }
}

.button {
  flex: 0 0 auto;
  padding-right: 8px;
}
