@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
}

.container {
  box-sizing: border-box;
  background-color: #d8d8d8;
  padding: 24px 16px 36px 16px;
}

.heading {
  padding-bottom: 16px;
}

.player {
  border: 1px solid rgba(0, 0, 0, 0.12);
  @include aspect-ratio(2, 1);

  @media (max-width: 899px) {
    @include aspect-ratio(16, 9);
  }
  @media (max-width: 599px) {
    @include aspect-ratio(1, 1);
  }

  max-height: calc(0.8 * var(--vh100, 100vh) - 72px);
}

.player_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-content: center;
}

.preview-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.overlay-action {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.18);
}
