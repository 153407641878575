@use "sass:map";
@use "../mixins";
@use "../variables";

.ChannelDetails {
  position: relative;
  &__banner {
    padding: 0 !important;
    img {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 155px;
    }
  }
  &__user {
    position: absolute !important;
    bottom: 0.7rem;
    padding: 0 0.5rem;
    img {
      object-fit: cover;
      object-position: top;
      border: 1px solid #000;
      margin-left: 0.7rem;
      width: 120px;
      height: 120px;
    }
    span {
      color: #fff;
      margin-left: 0.7rem;
      text-transform: capitalize;
    }
  }
  &__channel {
    &__edit {
      a {
        background-color: map.get(variables.$blue, main);
        height: 36px;
        width: 100%;
        @include mixins.Flex(row, center, center);
        @include mixins.fontStyle(14px, 400, #fff);
        border-radius: 0.25rem;
        text-transform: uppercase;
        text-decoration: none;
        margin-top: 0.5rem;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__add {
      a {
        background-color: map.get(variables.$blue, main);
        height: 36px;
        width: 100%;
        @include mixins.Flex(row, center, center);
        @include mixins.fontStyle(14px, 400, #fff);
        border-radius: 0.25rem;
        text-transform: uppercase;
        text-decoration: none;
        margin-top: 0.5rem;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.nav.nav-tabs {
  border-bottom: 1px solid map.get(variables.$gray, main);
  a {
    border-color: transparent !important;
    border: 0;
    margin: 0 !important;
  }
}
