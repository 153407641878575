$titleBarHeight: 56px;

.container {
  background-color: rgba(0, 0, 0, 1);
  height: var(--vh100, 100vh);
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.title-bar {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: $titleBarHeight;
  background-repeat: repeat-x;
  background-image: url(../../assets/images/gradient.png)
}
.title-bar_content {
  display: flex;
  flex-flow: row nowrap;
  place-items: center;
  padding-left: 8px;
  padding-right: 8px;
}
.title-bar_avatar {
  border: 1px solid rbga(255, 255, 255, 0.82);
}
.title-bar_text {
  color: #ffffff;
  margin-left: 8px;
}

.start-button {
  position: absolute;
  top: $titleBarHeight;
  bottom: $titleBarHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}