@use "sass:map";
@use "../mixins";
@use "../variables";

.Terms {
  padding: 1rem;
  &__title {
    @include mixins.fontStyle(20px, 400, map.get(variables.$blue, main));
    @include mixins.Flex(row, center);
    margin-bottom: 1rem;
  }
  a {
    @include mixins.fontStyle(14px, 400, map.get(variables.$blue, main));
  }
}