.container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  max-height: calc( var(--vh100, 100vh) - 72px);
}

.image {
  object-fit: cover;
  flex: 1 1 auto;
  max-width: 100%;
}

.content {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  padding: 40px 24px 24px 16px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background-image: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  );

  @media (max-width: 599px){
    padding: 16px 24px 24px 16px;
  }
}

.copy {
  flex-grow: 1;
  max-width: 400px;

  @media (max-width: 599px){
    // max-width: 256px;
    max-width: 288px;
  }
}

.headline {
  margin-bottom: 24px;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  @media (max-width: 599px){
    flex-flow: column nowrap;
    align-items: flex-end;
    margin-top: 16px;
  }
}

.action {
  padding-left: 16px;
  padding-bottom: 8px;
  button {
    width: 192px;
  }
}