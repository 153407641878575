.content {
  padding: 0 48px;

  @media (max-width: 899px) {
    padding: 0 24px;
  }

  @media (max-width: 599px) {
    padding: 0 12px;
  }
}

:global(.mdc-tab-bar .mdc-tab){
  min-width: 90px;
  max-width: 360px;
}


// Intended to be make a single Tab look like it cannot be clicked.
// Pairs with custom-tab-style.scss
.one-tab-only{
  button:not(:disabled){
    cursor: default;
  }
}