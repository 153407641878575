@use "../../styles/config";

.container {
  display: flex;
  width: 100%;  
}
.container__authoring {
  height: calc(
    var(--vh100, 100vh) - #{config.$appbar-height-normal} - #{config.$activityMetadata_collapsed_height}
  ); // fallback for unsupported browsers

  @media (max-width: 599px) {
    height: calc(
      var(--vh100, 100vh) - #{config.$appbar-height-dense} - #{config.$activityMetadata_collapsed_height}
    );
  }
}

.container__freeplay--fullsize {
  height: calc(
    var(--vh100, 100vh) - #{config.$appbar-height-normal}
  );

  @media (max-width: 599px) {
    height: calc(var(--vh100, 100vh) - #{config.$appbar-height-dense});
  }
}

.container--normal {
  height: calc(
    var(--vh100, 100vh) - #{config.$appbar-height-normal} - #{config.$activityDetails_distance-to-channel}
  );

  @media (max-width: 599px) {
    height: calc(
      var(--vh100, 100vh) - #{config.$appbar-height-dense} - #{config.$activityDetails_distance-to-channel}
    );
  }
}

.container--embed {
  height: 100vh; // fallback for unsupported browsers
  height: var(--vh100);
}

.container--fill {
  height: 100%;
}
