@use "sass:map";
@use "../mixins";
@use "../variables";

.Home {
  &__gray-element {
    height: 147px;
    background-color: map.get(variables.$gray, light);
  }

  &__presentation {
    &__desc {
      @include mixins.Flex(column);
      padding: 0.5rem;
      box-shadow: variables.$shadowBottom;

      & :first-child {
        @include mixins.fontStyle(18px, 600, map.get(variables.$gray, darker));
      }
    }
    &__stories {
      @include mixins.fontStyle(16px, 600, map.get(variables.$gray, darker));
      padding: 1.6rem 0;
    }
  }
  &__coming {
    @include mixins.Flex(column, center, center);
    height: 128px;
    background-color: map.get(variables.$blue, main);
    @include mixins.fontStyle(20px, 700, map.get(variables.$gray, darker));

    & :first-child {
      @include mixins.fontStyle(26px, 800, map.get(variables.$gray, darker));
      text-transform: uppercase;
    }
  }
  &__dev {
    &__title {
      @include mixins.Flex(column);
      padding: 0.5rem;
      color: map.get(variables.$gray, darker);
      box-shadow: variables.$shadowBottom;
    }
    &__links {
      ul {
        padding: 0;
        margin: 0;
        li {
          @include mixins.fontStyle(16px, 400, map.get(variables.$blue, main));
          margin: 1rem 0;
          list-style: none;
        }
      }
    }
  }
}
