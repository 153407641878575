.content {
  padding: 0 48px;

  @media (max-width: 899px) {
    padding: 0 24px;
  }

  @media (max-width: 599px) {
    padding: 0 12px;
  }
}

.page-title {
  margin-top: 24px;
}

.your-channels {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.your-channels_heading {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
}
.your-channels_manage {
  display: block;
  // flex-flow: row nowrap;
  // flex: 0 0 auto;
}