.container {
  box-sizing: border-box;
  width: 218px;
  padding: 8px 4px 24px 4px;
  justify-content: center;
  height: 210px; //hardcoded temp value to fix zSpace chromium dashboard bug
}
.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 112px;
  height: 112px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.card {
  text-align: center;
}

.title {
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  flex-grow: 1;
}

.action-buttons {
  display: flex;
  flex-grow: 1;
  align-content: center;
}

.action-button {
  display: flex;
  flex-grow: 1;
  align-content: center;
}
