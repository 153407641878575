.content {
  padding: 0 48px;

  @media (max-width: 899px) {
    padding: 0 24px;
  }

  @media (max-width: 599px) {
    padding: 0 12px;
  }
}

.accessDenied {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;

  & h3 {
    font-size: 20px;
    margin: 0;
  }

  & p {
    margin-top: 5px;
  }
}
