
.container {
  touch-action: none;
}

.logo {
  margin-right: 8px;  
}

.title{
  cursor: pointer;
}

.account-menu_header{
  display: flex;
  flex-flow: row nowrap;
  padding: 0px 16px
}

.account-menu_header_avatar {
  display: block;
  padding-right: 8px;
}

.account-menu_header_user {
  display: flex;
  flex-flow: column nowrap;
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 4px;
  }
}