.activity-image {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  width: 360px;
  border: 1px solid rgba(0 , 0, 0, 0.38);
  border-radius: 4px;
  box-sizing: border-box;
}
.activity-image_header {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  // height: 36px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  
}
.activity-image_text {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  padding: 12px 12px 12px 8px;
  box-sizing: border-box;
}
.activity-image_placeholder {
  display: flex;
  align-self: center;
  width: 360px;
  height: 203px;
  border-radius: 4px;
  background-color: rgb(175, 174, 174);
  border: 1px solid rgb(175, 174, 174);
  box-sizing: border-box;
}
.field--fullwidth {
  width: 100%;
}
