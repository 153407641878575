.list-container {
  display: block;
  margin-top: 24px;
}

.content {
  padding: 0 48px;

  @media (max-width: 899px) {
    padding: 0 24px;
  }

  @media (max-width: 599px) {
    padding: 0 12px;
  }
}

.page-title {
  margin-top: 24px;
}
